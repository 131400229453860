import { Component, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef, input, computed, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { en as countriesEN } from '@jagomf/countrieslist';
import type { Observable } from 'rxjs';
import { EVENTSWITHFRIENDS, GENERAL } from '../../../app.constants';
import Country from '../../../shared/models/country.model';
import type User from '../../../shared/models/user.model';
import type { IMenuOptions, IMenuFilter } from '../../../shared/services/header.interfaces';
import { FlagsService } from '../../../shared/services/flags.service';
import { FilterableEventsHandlerBaseComponent } from '../../../shared/handlers/filterable-events-handler-base/filterable-events-handler-base.component';
import { SORTBY, SortUsersPipe } from '../../../shared/pipes/sort-users.pipe';
import { UserCardListComponent } from '../../../shared/components/user-card-list/user-card-list.component';
import { AsyncPipe } from '@angular/common';
import { HandlerActionButtonComponent } from '../../../shared/components/handler-action-button/handler-action-button.component';
import { FlagEventListFilteredComponent } from '../../../shared/components/flag-event-list-filtered/flag-event-list-filtered.component';
import { HeaderHandlerComponent } from '../../../shared/components/header-handler/header-handler.component';

@Component({
	selector: 'dflgr-country-handler',
	standalone: true,
	imports: [RouterOutlet],
	template: `<router-outlet/>`,
	styles: [`:host { width: 100%; }`]
})
export class CountryHandlerComponent { }


@Component({
	selector: 'dflgr-country-events-handler',
	standalone: true,
	imports: [HeaderHandlerComponent, FlagEventListFilteredComponent, HandlerActionButtonComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dflgr-header-handler [flag]="country()" [title]="handlerTitle()" [options]="handlerOptions" [filters]="handlerFilters" [backButton]="true"/>
		<div class="handler-content">
			<dflgr-flag-event-list-filtered [country]="country()" (listUpdated)="cdRef.detectChanges()" [showOnlyBy]="showOnlyBy"/>
		</div>
		<dflgr-handler-action-button icon="flag" label="Add flag" [action]="actionButtonAction"/>
	`
})
export class CountryEventsHandlerComponent extends FilterableEventsHandlerBaseComponent {
	readonly cdRef = inject(ChangeDetectorRef);
	readonly #flagsProvider = inject(FlagsService);
	readonly #router = inject(Router);
	readonly #route = inject(ActivatedRoute);

	@HostBinding('class.handler') readonly handlerClass = true;
	readonly handlerTitle = computed(
		() => this.country() ? countriesEN[this.country().id.toUpperCase()] : GENERAL.handlers.countries.single.name
	);
	readonly countryId = input.required<string>({alias: 'countryid'}); // from route path params
	readonly country = computed(() => new Country(this.countryId()));

	get actionButtonAction() {
		return this.requestAddFlag.bind(this);
	}

	get handlerOptions(): IMenuOptions[] {
		return [{
			text: 'Users',
			icon: 'people',
			forceShowAction: true,
			callback: () => this.#router.navigate([GENERAL.handlers.countries.single.users.path], { relativeTo: this.#route })
		}];
	}

	protected toggleFilter(val: EVENTSWITHFRIENDS): void {
		super.toggleFilter(val);
		this.cdRef.detectChanges();
	}

	private requestAddFlag(): void {
		if (this.country()) {
			this.#flagsProvider.requestAddFlag(this.country());
		}
	}
}


@Component({
	selector: 'dflgr-country-users-handler',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './country-handler.component.html',
	styleUrls: ['./country-handler.component.scss'],
	standalone: true,
	imports: [HeaderHandlerComponent, UserCardListComponent, AsyncPipe, SortUsersPipe]
})
export class CountryUsersHandlerComponent implements OnInit {
	readonly #cdRef = inject(ChangeDetectorRef);
	readonly #flagsProvider = inject(FlagsService);

	@HostBinding('class.handler') readonly handlerClass = true;
	readonly handlerTitle = computed(() =>
		this.country() ? countriesEN[this.country().id.toUpperCase()] : GENERAL.handlers.countries.single.name
	);
	readonly countryId = input.required<string>({alias: 'countryid'}); // from route path params
	readonly country = computed(() => new Country(this.countryId()));
	usersWithFlag$: Observable<User[]>;
	sortBy = SORTBY.times;

	get handlerSorters(): IMenuFilter[] {
		return [
			{
				text: 'Times',
				singleChoice: true,
				selected: this.sortBy === SORTBY.times,
				callback: this.toggleFilter.bind(this, SORTBY.times)
			},
			{
				text: 'First to get',
				singleChoice: true,
				selected: this.sortBy === SORTBY.first,
				callback: this.toggleFilter.bind(this, SORTBY.first)
			},
			{
				text: 'Last to get',
				singleChoice: true,
				selected: this.sortBy === SORTBY.last,
				callback: this.toggleFilter.bind(this, SORTBY.last)
			}
		] as IMenuFilter[];
	}

	ngOnInit(): void {
		this.usersWithFlag$ = this.#flagsProvider.getUsersByFlag(this.country());
	}

	toggleFilter(val: SORTBY) {
		this.sortBy = val;
		this.#cdRef.detectChanges();
	}
}
